import Request from '../Request';

export default class Auth extends Request {
  logIn(authData) {
    return this.request('post', 'auth/login', authData);
  }

  requestAuthKey() {
    return this.request('post', 'auth/request');
  }

  getUser() {
    return this.request('get', 'profile');
  }
}
