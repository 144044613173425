import axios from 'axios';
import { server } from "@/utils/helper";

const cancelHash = {};

export default class Request {
  constructor() {
    this.server = server.baseURL;
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  newError(originalError) {
    throw originalError;
  }

  request(method, url, data, options) {
    if (options?.cancel) cancelHash[options.cancel]?.();

    const authData = JSON.parse(localStorage.getItem('tgAdminAuth'));

    return axios({
      url: `${this.server}/${url}`,
      method,
      headers: { ...this.headers, 'Authorization': authData && authData.token ? 'Bearer ' + authData.token : null },
      data,
      withCredentials: options?.withCredentials != null ? options?.withCredentials : this.withCredentials,
      params: options?.params,
      cancelToken: new axios.CancelToken((c) => {
        if (options?.cancel) cancelHash[options.cancel] = c;
      }),
    })
      .then((response) => {
        // if ('success' in response.data && !response.data.success) {
        //   this.newError(response.data.message);
        // }
        //
        // // used with pagination request
        // if (response.data.meta && response.data.links) {
        //   return response.data;
        // }
        //
        return response.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        if (error?.response) {
          console.log(`${this.server}/${url}`);
          console.log(error.response?.status);
          console.log(error.response?.data);
        }

        // eslint-disable-next-line max-len
        return Promise.reject(error.response ? this.newError(error, error.response.status, error.response.data) : error);
      });
  }
}
