import Auth from './auth/auth';
import AdminTableLogs from "@/api/admin/tables/logs";
import AdminTableUsers from "@/api/admin/tables/users";
import ObjectTypes from "@/api/admin/settings/objectTypes";
import ObjectTypeFields from "@/api/admin/settings/objectTypeFields";
import Namespaces from "@/api/admin/settings/namespaces";
import Params from "@/api/admin/settings/params";
import AdminTableReports from "@/api/admin/tables/reports";
import AdminTableGroups from "@/api/admin/tables/groups";
import AdminTableFixations from "@/api/admin/tables/fixations";
import OrdersRequest from "@/api/admin/orders";
import DocumentsRequest from "@/api/admin/documents";
import AdminTablePayoutCompany from "@/api/admin/tables/dropdowns/payout-company";
import AgentsRequest from "@/api/admin/agents";

const auth = new Auth();
const adminLogsTable = new AdminTableLogs();
const adminUsersTable = new AdminTableUsers();
const adminGroupsTable = new AdminTableGroups();
const objectTypes = new ObjectTypes();
const objectTypeFields = new ObjectTypeFields();
const namespaces = new Namespaces();
const orders = new OrdersRequest();
const documents = new DocumentsRequest();
const settingParams = new Params();
const reportsTable = new AdminTableReports();
const fixationsTable = new AdminTableFixations();
const payoutTable = new AdminTablePayoutCompany();
const agents = new AgentsRequest();

export default {
    auth,
    adminLogsTable,
    adminUsersTable,
    objectTypes,
    objectTypeFields,
    namespaces,
    settingParams,
    reportsTable,
    adminGroupsTable,
    fixationsTable,
    orders,
    documents,
    payoutTable,
    agents
};
