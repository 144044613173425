import Request from '../../Request';
import * as qs from "qs";

export default class AdminTableFixations extends Request {
    listAction() {
        return this.request('get', 'admin/fixations');
    }


    listActionPaginated(page, perPage, search, sortField, sortDir, filters) {
        const queryString = qs.stringify(
            {
                page: page,
                limit: perPage,
                search: search,
                sortBy: sortField,
                sortDir: sortDir,
                filters: filters
            }
        );

        return this.request('get', 'admin/fixations/paginated?' + queryString, {}, {
            // params: {
            //     page: page,
            //     limit: perPage,
            //     search: search,
            //     sortBy: sortField,
            //     sortDir: sortDir,
            //     filters: filters
            // }
        });
    }
}