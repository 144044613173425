import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/form/order/:id',
        name: 'NewOrder',
        component: () => import('../views/public/order/NewOrderForm.vue'),
    },
    {
        path: '/form/document/assignment/:id',
        name: 'AssignmentDocumentForm',
        component: () => import('../views/admin/documents/AssignmentDocumentForm'),
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('../views/auth/AuthIndex.vue'),
        redirect: '/login',
        meta: {breadcrumb: 'Авторизация'},
        children: [
            {
                path: '/login',
                name: 'Login',
                meta: {
                    breadcrumb: 'Вход',
                    title: 'Вход',
                },
                component: () => import('../views/auth/LoginView.vue'),
            },
        ],
    },
    {
        path: '/agent',
        name: 'AgentIndex',
        component: () => import('../views/agent/AgentIndex'),
        redirect: '/agent/orders',
        children: [
            {
                path: '/agent/orders',
                name: 'AgentOrders',
                component: () => import('../views/agent/orders/OrdersList'),
            },
            {
                path: '/agent/orders/:id',
                name: 'AgentOrderDetail',
                component: () => import('../views/agent/orders/OrderDetail'),
            },
        ]
    },
    {
        path: '/',
        name: 'AdminIndex',
        component: () => import('../views/admin/AdminIndex'),
        // redirect: '/logs',
        children: [
            {
                path: '/logs',
                name: 'Logs',
                component: () => import('../views/admin/logs/LogsList'),
            },
            {
                path: '/users',
                name: 'Users',
                component: () => import('../views/admin/users/UsersList'),
            },
            {
                path: '/groups',
                name: 'Groups',
                component: () => import('../views/admin/groups/GroupsList'),
            },
            {
                path: '/fixations',
                name: 'Fixations',
                component: () => import('../views/admin/fixations/FixationsList'),
            },
            {
                path: '/orders',
                name: 'Orders',
                component: () => import('../views/admin/orders/OrdersList'),
                // children: [
                //     {
                //         path: ':id',
                //         name: 'OrderDetail',
                //         component: () => import('../views/admin/orders/OrderDetail'),
                //     },
                // ]
            },
            {
                path: '/orders/:id',
                name: 'OrderDetail',
                component: () => import('../views/admin/orders/OrderDetail'),
            },
            {
                path: '/services/message',
                name: 'MessageService',
                component: () => import('../views/admin/services/MessageView'),
            },
            {
                path: '/settings',
                name: 'Settings',
                component: () => import('../views/admin/settings/SettingsView'),
            },
            {
                path: '/dropdowns/payout',
                name: 'Payout',
                component: () => import('../views/admin/dropdowns/payout-company/PayoutCompanyList'),
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
