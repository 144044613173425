import Request from '../../Request';

export default class ObjectTypes extends Request {
    selectedListAction() {
        return this.request('get', 'admin/objects/types/selected');
    }

    listAction() {
        return this.request('get', 'admin/objects/types/all');
    }

    updateObjectTypesAction(objectTypesArray) {
        return this.request('post', 'admin/objects/types/update', {
            types: objectTypesArray
        });
    }
}