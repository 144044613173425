import Request from '../Request';

export default class DocumentsRequest extends Request {
    getDocumentGenerationData(genId) {
        return this.request('get', 'generation/document/' + genId);
    }

    sendDocumentData(genId, data) {
        return this.request('post', 'generation/document/' + genId + '/send', data);
    }
}