import Request from '../Request';
import * as qs from "qs";

export default class OrdersRequest extends Request {
    getPaginatedData(page, perPage, search, sortField, sortDir, filters) {
        const queryString = qs.stringify(
            {
                page: page,
                limit: perPage,
                search: search,
                sortBy: sortField,
                sortDir: sortDir,
                filters: filters
            }
        );

        return this.request('get', 'orders/paginated?' + queryString, {});
    }

    getAgentPaginatedData(page, perPage, search, sortField, sortDir, filters) {
        const queryString = qs.stringify(
            {
                page: page,
                limit: perPage,
                search: search,
                sortBy: sortField,
                sortDir: sortDir,
                filters: filters
            }
        );

        return this.request('get', 'orders/agent/paginated?' + queryString, {});
    }

    getSwiftData(swiftCode) {
        return this.request('get', 'orders/tools/swift/data?swift=' + swiftCode);
    }

    getCountriesDropdownData() {
        return this.request('get', 'orders/dropdowns/countries');
    }

    getCurrenciesDropdownData() {
        return this.request('get', 'orders/dropdowns/currencies');
    }

    getTaskOrder(orderTaskId) {
        return this.request('get', 'orders/task/' + orderTaskId);
    }

    getOrderDetail(orderId) {
        return this.request('get', 'orders/' + orderId + '/detail');
    }

    getAgentOrderDetail(orderId) {
        return this.request('get', 'orders/agent/' + orderId + '/detail');
    }

    changeStatusOfOrder(orderId, status, comment) {
        return this.request('post', 'orders/agent/' + orderId + '/changeStatus', {
            status: status,
            comment: comment,
        });
    }

    sendOrderAction(orderTaskId, data) {
        return this.request('post', 'orders/task/' + orderTaskId + '/send', data);
    }
}