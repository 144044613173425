import Request from '../../../Request';
import * as qs from "qs";

export default class AdminTablePayoutCompany extends Request {
    detailAction(id) {
        return this.request('get', 'dropdowns/payout/' + id);
    }

    listAction() {
        return this.request('get', 'dropdowns/payout');
    }


    listActionPaginated(page, perPage, search, sortField, sortDir, filters) {
        const queryString = qs.stringify(
            {
                page: page,
                limit: perPage,
                search: search,
                sortBy: sortField,
                sortDir: sortDir,
                filters: filters
            }
        );

        return this.request('get', 'dropdowns/payout/paginated?' + queryString, {}, {
            // params: {
            //     page: page,
            //     limit: perPage,
            //     search: search,
            //     sortBy: sortField,
            //     sortDir: sortDir,
            //     filters: filters
            // }
        });
    }

    createAction(data) {
        return this.request('post', `dropdowns/payout/create`, data);
    }

    updateAction(id, data) {
        return this.request('post', `dropdowns/payout/${id}/update`, data);
    }

    deleteAction(id) {
        return this.request('post', `dropdowns/payout/${id}/delete`);
    }
}