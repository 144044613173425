import Request from '../../Request';

export default class ObjectTypeFields extends Request {
    createdListAction() {
        return this.request('get', 'admin/objects/fields/created');
    }

    listAction() {
        return this.request('get', 'admin/objects/fields/all');
    }

    fieldTypesAction() {
        return this.request('get', 'admin/objects/fields/types');
    }

    updateObjectTypeFieldsAction(objectTypesFields) {
        return this.request('post', 'admin/objects/fields/update', {
            fields: objectTypesFields
        });
    }
}