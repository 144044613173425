import Request from '../../Request';

export default class Namespaces extends Request {
    selectedListAction() {
        return this.request('get', 'admin/namespaces/selected');
    }

    listAction() {
        return this.request('get', 'admin/namespaces/all');
    }

    updateAction(namespacesArray) {
        return this.request('post', 'admin/namespaces/update', {
            namespaces: namespacesArray
        });
    }
}