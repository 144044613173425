import API from '@/api';

export default {
  namespaced: true,

  state: {
    isAuth: null,
    userInfo: null,
    authVerification: null,
  },

  getters: {
    isAuth(state) {
      return !!state.isAuth;
    },

    userInfo(state) {
      return state.userInfo;
    },

    authVerification(state) {
      return state.authVerification;
    },
  },

  mutations: {
    setAuth(state, data) {
      console.log('setAuth', state, data);
      if (!data) localStorage.removeItem('tgAdminAuth');
      else localStorage.setItem('tgAdminAuth', JSON.stringify(data));

      state.isAuth = data?.token || null;
      state.userInfo = data?.userInfo || null;
    },

    setAuthVerification(state, status) {
      state.authVerification = status;
    },
  },

  actions: {
    async verifyAuthorization({ getters, commit, dispatch }) {
      // Если авторизация уже верифицирована, ничего не делаем
      if (getters.authVerification) return true;

      // 1. Смотрим есть ли токен
      const localAuthData = localStorage.getItem('tgAdminAuth');
      const hasTokenInStore = JSON.parse(localAuthData)?.token || false;

      // Если токена нет в LS, возвращаем отказ, редиректим на стр. входа
      if (!hasTokenInStore && !getters.isAuth) return false;

      // 2. Можем ли мы с этим токеном отправлять запросы
      // Отправляем тестовый запрос на юзера
      const getUser = await dispatch('getUser');

      console.log('getUser', getUser);

      if (getUser?.userId) commit('setAuthVerification', true);
      else commit('setAuthVerification', false);
      // Что делать, когда нет сети, например
      // не работает
      // else return 'networkError';

      // 3. Если не получили ответ, разлогиниваемся
      if (!getters.authVerification) dispatch('logOut');

      // 4. Есть ключ в LS, но не сохранен в store, пишем его в state
      if (getters.authVerification && !getters.isAuth) commit('setAuth', JSON.parse(localAuthData));

      // Возвращаем результат проверки
      return getters.authVerification;
    },

    // Запрос авторизации
    async logIn({ commit }, authData) {
      try {
        const response = await API.auth.logIn(authData);

        // Распределяем инфо по хранилищам
        commit('setAuth', { token: response?.token, userInfo: response?.user });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    logOut({ commit }) {
      commit('setAuth', null);
    },

    // eslint-disable-next-line no-unused-vars
    async getUser({ commit }) {
      let response = null;

      try {
        response = await API.auth.getUser();

      } catch (error) {
        // if (error?.response?.data?.code === 403) response = 'denied';
        // else response = 'error';
        response = false;
      }

      return response;
    },
  },
};
