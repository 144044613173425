import Request from '../../Request';

export default class Params extends Request {
    listAction() {
        return this.request('get', 'admin/settings/all');
    }

    updateAction(settingsArray) {
        return this.request('post', 'admin/settings/update', {
            settings: settingsArray
        });
    }
}